.toolbar[data-v-72a1a2f7] {
  display: flex;
  flex-direction: var(--72a1a2f7-flexDirection);
  justify-content: space-between;
  flex-wrap: wrap;
}
.toolbar .tool_left[data-v-72a1a2f7] {
  display: flex;
  flex-direction: var(--72a1a2f7-flexDirection);
  flex-wrap: wrap;
}
.toolbar .tool_left .organizational_unit[data-v-72a1a2f7] {
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 8px;
}
.toolbar .tool_left .dw_department[data-v-72a1a2f7] {
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 8px;
}
.toolbar .tool_right[data-v-72a1a2f7] {
  display: flex;
  flex-direction: var(--72a1a2f7-flexDirection);
  justify-content: flex-end;
  flex-wrap: wrap;
}
.toolbar .tool_right .tool_dropdown_menu[data-v-72a1a2f7] {
  margin-right: 20px;
  margin-bottom: 8px;
}
